var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import { isEasyCashNative, isAndroid } from 'ssr-common/util/webview/ua';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import BrandCountryMap from 'ssr-common/vue/constant/brand-country';
import Event from 'easycash/common/resource/event';
export default {
    uploadEvent: function (info, route, BRAND, useAppMethod) {
        if (info === void 0) { info = {}; }
        return __awaiter(void 0, void 0, Promise, function () {
            var _a, elementType, _b, elementContent, _c, tag, _d, businessEvent, _e, eventValue, _f, eventType, name, path, fullPath, elementId, params, ua, sensors, dataConvert;
            var _g;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        _a = info.elementType, elementType = _a === void 0 ? 'Button' : _a, _b = info.elementContent, elementContent = _b === void 0 ? '' : _b, _c = info.tag, tag = _c === void 0 ? '' : _c, _d = info.businessEvent, businessEvent = _d === void 0 ? '' : _d, _e = info.eventValue, eventValue = _e === void 0 ? '' : _e, _f = info.eventType, eventType = _f === void 0 ? 'C' : _f;
                        name = route.name, path = route.path, fullPath = route.fullPath;
                        elementId = tag ? "".concat(name).concat(tag) : name;
                        params = {
                            events: [{
                                    eventType: eventType,
                                    businessEvent: businessEvent,
                                    eventValue: eventValue,
                                    componentId: "".concat(elementId, ":").concat(BRAND).concat(path),
                                    eventParams: {
                                        activity: "".concat(BRAND, ":").concat(fullPath),
                                        elementType: elementType,
                                        elementId: elementId,
                                        elementContent: elementContent
                                    },
                                    eventTime: Date.now(),
                                    platformType: 'WEB'
                                }]
                        };
                        ua = (_g = window === null || window === void 0 ? void 0 : window.navigator) === null || _g === void 0 ? void 0 : _g.userAgent;
                        if (useAppMethod && isEasyCashNative(ua) && isAndroid(ua)) {
                            try {
                                WebviewInterface.callHandler('uploadEvent', JSON.stringify(params.events[0]));
                                return [2];
                            }
                            catch (error) {
                            }
                        }
                        return [4, Event.uploadEvents(params, {
                                hideLoading: true,
                                hideToast: true,
                                headers: {
                                    'YQG-PLATFORM-ENVIRONMENT-INFO': 'WEB',
                                    'Country': BrandCountryMap[BRAND]
                                }
                            }).catch(function (error) { return error; })];
                    case 1:
                        _h.sent();
                        sensors = Vue.prototype.$sensors;
                        if (sensors && businessEvent) {
                            try {
                                dataConvert = typeof elementContent === 'string' ? JSON.parse(elementContent) : elementContent;
                                sensors.track(businessEvent, { elementContent: dataConvert });
                            }
                            catch (error) {
                            }
                        }
                        return [2];
                }
            });
        });
    }
};
