/**
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58.263
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-08-Tu 04:12:41
 */

import EcImage from 'ssr-common/vue/component/ec-image';

import PaFooter from './pa-footer';
import PaHeader from './pa-header';
import PaModal from './pa-modal';

export default {

    install(Vue) {
        Vue.component('ec-image', EcImage);

        Vue.component('pa-header', PaHeader);
        Vue.component('pa-footer', PaFooter);
        Vue.component('pa-modal', PaModal);
    }

};
