/*
 * @Author: zhaoyang
 * @Date: 2023-10-18 14:50:07
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-10-18 15:49:43
 */

import User from 'mabilis-cash/common/resource/user';

export default {
    namespaced: true,

    state: () => ({
        status: {}
    }),

    mutations: {
        SET_USER_STATUS: (state, data) => {
            state.status = data ?? {};
        }
    },

    actions: {
        async getUserStatus({commit}) {
            const {data: {body}} = await User.userStatus({
                hideLoading: true,
                hideToast: true
            });
            commit('SET_USER_STATUS', body);
        }
    }
};
