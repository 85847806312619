/**
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58.263
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-05-We 03:23:46
 */
/* global __GTAG_ID__ */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import VueGtag from 'vue-gtag';

import {rejectFailedStatus} from '@yqg/http';
import 'ssr-common/util/report';

import {initSentry} from '@shared/client/util/sentry';

import {isServer} from 'ssr-common/constant/config';
import {setSentryUser} from 'ssr-common/util/sentry-user';
import webviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import Vue from 'ssr-common/vue';
import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';
import http from 'ssr-common/vue/vue-http';

import EventUtil from 'easycash/common/util/event';

import {rootTitle, BRAND} from './common/constant/config';
import {createApp} from './main';

http.interceptors.response.use(rejectFailedStatus());

const {router} = createClientEntry({createApp, rootTitle});

Vue.use(VueGtag, {
    config: {id: __GTAG_ID__}
}, router);

router.afterEach(to => {
    const {query, meta = {}, name} = to;
    const {business = {}} = meta;
    const {
        enterEvent,
        uploadQuery,
        buriedPoint,
        bridge
    } = business;

    if (bridge) {
        if (typeof bridge === 'string' && webviewInterface[bridge]) {
            webviewInterface[bridge]();
        } else if (bridge?.name) {
            webviewInterface.callHandler(bridge.name, bridge.params || '', bridge.callback || '');
        }
    }

    if (enterEvent && !isServer() && !buriedPoint) {
        const {elementContent = {}} = enterEvent;
        const event = {
            ...enterEvent,
            elementContent: uploadQuery ? JSON.stringify({
                ...query, ...elementContent
            }) : JSON.stringify({...elementContent})
        };
        EventUtil.uploadEvent(event, to, BRAND);
    }

    if (buriedPoint && !isServer()) {
        const finalQuery = query;

        const event = {
            tag: 'PageEnter',
            businessEvent: `Mabilis-cash_${name}_PageEnter`,
            elementType: 'Button',
            eventType: 'P',
            elementContent: uploadQuery ? finalQuery : {}
        };
        EventUtil.uploadEvent(event, to, BRAND);
    }
});

initSentry({
    router,
    dsn: 'https://63fc9a6a28102a17e2a6b4997c5a4f9c@sentry.fintopia.tech/73'
});

setSentryUser();

if (module.hot) {
    module.hot.accept();
}
