/*
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-02-23 17:26:27
 */

/* global __API_HOST__ RUN_PORT */

export const rootTitle = 'Mabilis Cash - Make your life easier with Mabilis Cash';

export const API_HOST = __API_HOST__;

export const downloadUrl = 'https://play.google.com/store/apps/details?id=com.loans.lending.onlinelend.mabiliscash';

export const marketUrl = 'market://details?id=com.loans.lending.onlinelend.mabiliscash';

export const iosMarketUrl = 'https://itunes.apple.com/app/id6470179795';

export const getEurekaHost = () =>
    (typeof window !== 'undefined' && window.location.origin)
    || `http://127.0.0.1:${RUN_PORT}`;

export const BRAND = 'mabilis-cash';
