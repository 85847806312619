/*
 * @Author: bowang
 * @Date: 2023-01-06 15:46:29
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-02-27 18:07:23
 */

import BrandCountryMap from 'ssr-common/vue/constant/brand-country';

import {BRAND} from 'mabilis-cash/common/constant/config';
import Event from 'mabilis-cash/common/resource/event';

export default {
    uploadEvent: async (info = {}, route) => {
        const {
            elementType = 'Button',
            elementContent = '',
            tag = '',
            businessEvent = '',
            eventValue = '',
            eventType = 'C'
        } = info;

        const {name, path, fullPath} = route;
        const elementId = tag ? `${name}${tag}` : name;

        const params = {
            events: [{
                eventType,
                businessEvent,
                eventValue,
                componentId: `${elementId}:${BRAND}${path}`,
                eventParams: {
                    activity: `${BRAND}:${fullPath}`,
                    elementType,
                    elementId,
                    elementContent
                },
                eventTime: Date.now(),
                platformType: 'WEB'
            }]
        };
        await Event.uploadEvents(params, {
            hideLoading: true,
            hideToast: true,
            headers: {
                'YQG-PLATFORM-ENVIRONMENT-INFO': 'WEB',
                'Country': BrandCountryMap[BRAND]
            }});
    }
};
