/**
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58.263
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:54
 */

import AntdIcon from './antd-icon';

export default {

    install(Vue) {
        Vue.use(AntdIcon);
    }

};
