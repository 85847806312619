/*
 * @Author: qxy
 * @Date: 2019-08-23 17:47:17
 * @Last Modified by: qxy
 * @Last Modified time: 2019-08-26 13:23:39
 */

import VueI18n from 'vue-i18n';

import Vue from 'ssr-common/vue';

import EN from './languages/en.json';

Vue.use(VueI18n);

export const createI18n = context => new VueI18n({
    locale: context.acceptLanguage,
    fallbackLocale: 'en',
    messages: {
        en: EN
    },
    silentTranslationWarn: true
});

