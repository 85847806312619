import * as Sentry from '@sentry/vue';
export var apiErrorReportToSentry = function (request, response) {
    try {
        var _a = request || {}, url_1 = _a.url, method_1 = _a.method, params_1 = _a.params, data_1 = _a.data;
        var _b = response || {}, httpCode_1 = _b.httpCode, code_1 = _b.code, traceId_1 = _b.traceId, detail_1 = _b.detail;
        var isFileUploadApi_1 = data_1 instanceof FormData;
        Sentry.withScope(function (scope) {
            scope.setLevel('info');
            scope.setTags({
                httpCode: httpCode_1,
                isFileUploadApi: isFileUploadApi_1,
                requestUrl: url_1,
                requestMethod: method_1,
                responseCode: code_1,
                responseTraceId: traceId_1,
                responseMessage: detail_1
            });
            scope.setContext('requestParams', { params: params_1, data: data_1 });
            Sentry.captureException(new Error(url_1));
        });
    }
    catch (error) {
    }
};
