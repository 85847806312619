/*
 * @Author: qxy
 * @Date: 2019-08-26 15:07:25
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2020-02-26 23:17:35
 */

import AntdIcon from '@ant-design/icons-vue';
import RightOutline from '@ant-design/icons/lib/outline/RightOutline';

import CardFourOutline from 'easycash/common/plugin/antd-icon/custom/outline/card-four';
import CardOneOutline from 'easycash/common/plugin/antd-icon/custom/outline/card-one';
import CardThreeOutline from 'easycash/common/plugin/antd-icon/custom/outline/card-three';
import CardTwoOutline from 'easycash/common/plugin/antd-icon/custom/outline/card-two';
import ServiceOutline from 'easycash/common/plugin/antd-icon/custom/outline/service';
import TelephoneOutline from 'easycash/common/plugin/antd-icon/custom/outline/telephone';

AntdIcon.add(
    RightOutline,
    CardOneOutline,
    CardTwoOutline,
    CardThreeOutline,
    CardFourOutline,
    TelephoneOutline,
    ServiceOutline
);

export default AntdIcon;
