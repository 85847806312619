/**
 * @Author: xiaoyaqu
 * @Date: 2019-08-19 13:42:58.263
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2024-05-We 03:21:19
 */

import BusinessEvents from 'mabilis-cash/common/constant/business-events';

import RoutesActivity from './routes-activity';

const routes = [
    {
        path: '',
        name: 'index',
        component: () => import('mabilis-cash/app/index'),
        redirect: {name: 'borrow'},
        children: [
            {
                path: '/borrow',
                name: 'borrow',
                component: () => import('mabilis-cash/app/index/borrow')
            },
            {
                path: '/faq',
                name: 'faq',
                component: () => import('mabilis-cash/app/index/faq')
            },
            {
                path: '/blogs',
                name: 'blogs',
                component: () => import('mabilis-cash/app/index/blog-v2')
            },
            {
                path: '/blog/:blogPath',
                name: 'blogpage',
                component: () => import('mabilis-cash/app/index/blog-page-v2')
            }
        ]
    },
    {
        path: '/webview',
        name: 'Webview',
        component: () => import('mabilis-cash/app/webview'),
        children: [
            {
                path: 'skypay',
                name: 'skypay',
                component: () => import('mabilis-cash/app/webview/skypay'),
            },
            {
                path: 'help-center',
                name: 'helpCenter',
                component: () => import('mabilis-cash/app/webview/help-center'),
                redirect: {name: 'question', params: {type: 'loan'}},
                children: [
                    {
                        path: ':type',
                        name: 'question',
                        component: () => import('mabilis-cash/app/webview/help-center/question')
                    }
                ]
            },
            {
                path: 'agreement',
                name: 'agreement',
                component: () => import('easycash/app/webview/agreement')
            },
            {
                path: 'static-agreement/:key',
                name: 'staticAgreement',
                component: () => import('easycash/app/webview/static-agreement')
            },
            {
                path: 'static-text/:key',
                name: 'staticText',
                component: () => import('easycash/app/webview/static-text')
            },
            {
                path: 'credit-risk-score',
                name: 'creditRiskScore',
                component: () => import('mabilis-cash/app/webview/credit-risk-score')
            },
            {
                path: 'repayment',
                name: 'repayment',
                component: () => import('mabilis-cash/app/webview/repayment'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.RepaymentPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'repay-result',
                name: 'repayResult',
                component: () => import('mabilis-cash/app/webview/repay-result')
            },
            {
                path: 'test-bridge',
                name: 'testBridge',
                component: () => import('ssr-common/vue/component/test-bridge')
            },
            {
                path: 'loan-register',
                name: 'loanRegister',
                component: () => import('mabilis-cash/app/webview/loan-register'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.LoanRegisterPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'unsubscribe',
                name: 'unsubscribe',
                component: () => import('mabilis-cash/app/webview/unsubscribe'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.UnsubscribePageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'delete-account',
                name: 'deleteAccount',
                component: () => import('mabilis-cash/app/webview/unsubscribe/delete-account'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'delete-result',
                name: 'deleteResult',
                component: () => import('mabilis-cash/app/webview/unsubscribe/delete-result'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'credit',
                name: 'Credit',
                component: () => import('mabilis-cash/app/webview/credit'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'credit-gcash',
                name: 'CreditGcash',
                component: () => import('mabilis-cash/app/webview/credit/gcash'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        bridge: 'setBackCallbackEnable'
                    }
                }
            },
            {
                path: 'credit-prc',
                name: 'CreditPrc',
                component: () => import('mabilis-cash/app/webview/credit/prc'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        bridge: 'setBackCallbackEnable'
                    }
                }
            },
            {
                path: 'credit-mobile',
                name: 'CreditMobile',
                component: () => import('mabilis-cash/app/webview/credit/mobile'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        bridge: 'setBackCallbackEnable'
                    }
                }
            },
            {
                path: 'credit-gsis',
                name: 'CreditGsis',
                component: () => import('mabilis-cash/app/webview/credit/gsis'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        bridge: 'setBackCallbackEnable'
                    }
                }
            },
            {
                path: 'credit-others',
                name: 'CreditOthers',
                component: () => import('mabilis-cash/app/webview/credit/others'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        bridge: 'setBackCallbackEnable'
                    }
                }
            },
            {
                path: 'credit-mysss',
                name: 'CreditMysss',
                component: () => import('mabilis-cash/app/webview/credit/mysss'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        bridge: 'setBackCallbackEnable'
                    }
                }
            },
            {
                path: 'credit-gcash-bill',
                name: 'CreditGcashBill',
                component: () => import('mabilis-cash/app/webview/credit/gcash-bill'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true,
                        bridge: 'setBackCallbackEnable'
                    }
                }
            },
            {
                path: 'register-landing',
                name: 'RegisterLanding',
                component: () => import('mabilis-cash/app/webview/register-landing'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            ...RoutesActivity
        ]
    },

    {
        path: '/r-l/:code?',
        name: 'RegisterLanding',
        component: () => import('mabilis-cash/app/webview/register-landing'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },

    {
        path: '*',
        component: () => import('easycash/app/not-found')
    }
];

const routesToMap = (routeArr, map = {}) => {
    routeArr.forEach(route => {
        const {children, name, ...rest} = route;
        if (name) map[name] = {name, ...rest};
        if (children) routesToMap(children, map);
    });

    return map;
};

export const scrollBehavior = (to, from, savePosition) => {
    if (savePosition) {
        return savePosition;
    }

    const position = {};

    if (to.hash) {
        position.selector = to.hash;
    }

    if (window.innerWidth > 768) {
        position.offset = {y: 80};
    } else {
        position.offset = {y: 120};
    }

    return position;
};

export const routeMap = routesToMap(routes);

export default routes;
