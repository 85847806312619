/*
 * @Author: bowang
 * @Date: 2023-01-06 15:53:16
 * @Last Modified by: bowang
 * @Last Modified time: 2023-01-06 15:53:39
 */

import Resource from '@yqg/resource';

const api = {
    uploadEvents: {
        url: '/event/uploadEvents/v2',
        method: 'post'
    }
};

export default Resource.create(api);
