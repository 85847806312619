var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default {
    bind: function (el, _a, vnode) {
        var value = _a.value, arg = _a.arg, modifiers = _a.modifiers;
        var input = el.getElementsByTagName('input')[0];
        var handleEvent = function (event, type) {
            var _a;
            var elementContent = __assign({}, value);
            if (['Input', 'Focus', 'Blur'].includes(type)) {
                elementContent.inputVal = (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value;
            }
            var eventName = "".concat(vnode.context.$route.name, "_").concat(arg, "Input_");
            vnode.context.$app.uploadClickEvent("".concat(eventName).concat(type), elementContent);
        };
        if (modifiers.click) {
            input.addEventListener('click', function (event) { return handleEvent(event, 'Click'); });
        }
        if (modifiers.focus) {
            input.addEventListener('focus', function (event) { return handleEvent(event, 'Focus'); });
        }
        if (modifiers.blur) {
            input.addEventListener('blur', function (event) { return handleEvent(event, 'Blur'); });
        }
        if (modifiers.input) {
            input.addEventListener('input', function (event) { return handleEvent(event, 'Input'); });
        }
    }
};
