
import EventUtil from 'mabilis-cash/common/util/event';

export default BRAND => ({
    bind(el, binding, vnode) {
        const {value} = binding;
        if (!value) {
            return;
        }

        if (value.type === 'input') {
            const {tag, businessEvent, uploadValue, ...otherParams} = value;
            const focusParams = {
                tag: `${tag}InputFocus`,
                businessEvent: `${businessEvent}InputFocus`,
                elementType: 'Button',
                eventType: 'C',
                ...otherParams
            };
            const blurParams = {
                tag: `${tag}InputBlur`,
                businessEvent: `${businessEvent}InputBlur`,
                elementType: 'Button',
                eventType: 'C',
                ...otherParams
            };
            el.addEventListener('focus', () => {
                EventUtil.uploadEvent(focusParams || {}, vnode.context.$route, BRAND);
            });
            el.addEventListener('blur', event => {
                EventUtil.uploadEvent(
                    (uploadValue ? {
                        ...blurParams,
                        ...otherParams,
                        elementContent: {
                            ...otherParams.elementContent,
                            [typeof uploadValue === 'string' ? uploadValue : 'inputVal']: event?.target?.value
                        }
                    } : blurParams) || {},
                    vnode.context.$route,
                    BRAND
                );
            });
        } else {
            el.addEventListener('click', () => {
                EventUtil.uploadEvent(value || {}, vnode.context.$route, BRAND);
            });
        }
    }
});
