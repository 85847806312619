/*
 * @Author: wuhuiwang 
 * @Date: 2024-08-Tu 11:39:43 
 * @Last Modified by:   wuhuiwang 
 * @Last Modified time: 2024-08-Tu 11:39:43 
 */

const routes = [
    // 转盘活动
    {
        path: 'activity/lift-lottery/:activityId',
        name: 'LiftLottery',
        component: () => import('mabilis-cash/app/webview/activity/lift-lottery'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: 'activity/lift-lottery/:activityId/history',
        name: 'LiftLotteryHistory',
        component: () => import('mabilis-cash/app/webview/activity/lift-lottery/history'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    // 新客大礼包
    {
        path: 'activity/exclusiveRewards',
        name: 'ExclusiveRewards',
        component: () => import('mabilis-cash/app/webview/activity/exclusive-rewards')
    }
];

export default routes;
