/*
 * @Author: yuanyuanliu
 * @Date: 2023-01-11 19:28:43
 * @Last Modified by:   yuanyuanliu
 * @Last Modified time: 2023-01-11 19:28:43
 */

export default {
    namespaced: true,

    state: () => ({
        brand: 'mabilis-cash'
    })
};
