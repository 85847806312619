/*
 * @Author: bowang
 * @Date: 2023-01-06 15:56:27
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-12-04 17:34:04
 */

export default {
    // 进入页面
    LoanRegisterPageEnter: {
        tag: 'LoanRegisterPageEnter',
        businessEvent: 'Phi_LoanRegisterPageEnter_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    // 获取验证码
    LoanRegisterGetCodeButton: {
        tag: 'GetCodeButton',
        businessEvent: 'Phi_LoanRegister_GetCode_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 点击注册/登录
    LoanRegisterLoginButton: {
        tag: 'RegisterLoginButton',
        businessEvent: 'Phi_LoanRegister_RegisterLogin_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 点击协议
    LoanRegisteAgreementButton: {
        tag: 'AgreementButton',
        businessEvent: 'Phi_LoanRegister_Agreement_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 跳转app store
    LoanRegisterAppStoreButton: {
        tag: 'AppStoreButton',
        businessEvent: 'Phi_LoanRegister_AppStore_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 下载apk
    LoanRegisterDownloadAppButton: {
        tag: 'DownloadAppButton',
        businessEvent: 'Phi_LoanRegister_DownloadApp_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 进入还款页
    RepaymentPageEnter: {
        tag: 'RepaymentPageEnter',
        businessEvent: 'Peso-agad_RepaymentPageEnter_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    // 还款页点击分类
    RepaymentTabButton: {
        tag: 'RepaymentTabButton',
        businessEvent: 'Phi_Repayment_Tab_Button',
        elementType: 'Button',
        eventType: 'C'
    },
    // 进入还款详情页
    SkypayPageEnter: {
        tag: 'SkypayPageEnter',
        businessEvent: 'Mabilis-cash_SkypayPageEnter_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    // 进入注销页面
    UnsubscribePageEnter: {
        tag: 'UnsubscribePageEnter',
        businessEvent: 'Mabilis-cash_UnsubscribePageEnter_Enter',
        elementType: 'Button',
        eventType: 'P'
    }
};
